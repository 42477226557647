import { documentReady } from "./helpers/dom";
import { ReplaySubject } from "rxjs";
import { withLatestFrom, map } from "rxjs/operators";
import { configureView } from "./view";
import { createAudioEventStream, AudioEvent } from "./helpers/audio";
import { playerState$ } from "./state/playerState";

const audio = new Audio(
  "https://download.omroep.nl/nos/radionieuws/nosnieuws_bulalg.mp3"
);

const audioEvent$ = createAudioEventStream(audio);
audioEvent$
  .pipe(withLatestFrom(playerState$))
  .subscribe(([event, playerState]) => {
    switch (event) {
      case AudioEvent.Playing:
        playerState$.next({
          ...playerState,
          isPlaying: true
        });
        return;
      case AudioEvent.Pause:
      case AudioEvent.Ended:
        playerState$.next({
          ...playerState,
          isPlaying: false
        });
        return;
      case AudioEvent.DurationChange:
        playerState$.next({
          ...playerState,
          duration: audio.duration
        });
        return;
      case AudioEvent.TimeUpdate:
        playerState$.next({
          ...playerState,
          secondsPlayed: audio.currentTime
        });
        return;
    }
  });

const onClickButton$ = new ReplaySubject<Event>();
onClickButton$
  .pipe(
    withLatestFrom(playerState$),
    map(([_, playerState]) => playerState.isPlaying)
  )
  .subscribe(isPlaying => {
    if (!isPlaying) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  });

const documentReady$ = documentReady(document);
documentReady$.subscribe(() => {
  configureView(document, playerState$, onClickButton$);
});
