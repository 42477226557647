import { fromEvent, Observable } from "rxjs";

export enum AudioEvent {
  Playing = "playing",
  Pause = "pause",
  Ended = "ended",
  DurationChange = "durationchange",
  TimeUpdate = "timeupdate"
}

export const createAudioEventStream = (audioElement: HTMLAudioElement) =>
  new Observable<AudioEvent>(subscriber => {
    fromEvent(audioElement, AudioEvent.Playing).subscribe(() =>
      subscriber.next(AudioEvent.Playing)
    );

    fromEvent(audioElement, AudioEvent.Pause).subscribe(() =>
      subscriber.next(AudioEvent.Pause)
    );

    fromEvent(audioElement, AudioEvent.Ended).subscribe(() =>
      subscriber.next(AudioEvent.Ended)
    );

    fromEvent(audioElement, AudioEvent.DurationChange).subscribe(() => {
      subscriber.next(AudioEvent.DurationChange);
    });

    fromEvent(audioElement, AudioEvent.TimeUpdate).subscribe(() => {
      subscriber.next(AudioEvent.TimeUpdate);
    });
  });
