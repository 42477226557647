import { BehaviorSubject } from "rxjs";

export interface PlayerState {
  isPlaying: boolean;
  secondsPlayed: number;
  duration: number;
}

export const playerState$ = new BehaviorSubject<PlayerState>({
  isPlaying: false,
  secondsPlayed: 0,
  duration: 0
});
