import { fromEvent, Subject } from "rxjs";
import { PlayerState } from "./state/playerState";
import { humanReadableTime } from "./helpers/humanTime";

export const configureView = (
  document: Document,
  playerState$: Subject<PlayerState>,
  onClickButton$: Subject<Event>
) => {
  const button = document.createElement("button");
  button.classList.add("player__button");
  fromEvent(button, "click").subscribe(event => onClickButton$.next(event));

  const progressBar = document.createElement("div");
  progressBar.classList.add("player__progress-bar");

  const progressSecondsPlayed = document.createElement("span");
  progressSecondsPlayed.classList.add("progress-bar__time");

  const progressDuration = document.createElement("span");
  progressDuration.classList.add("progress-bar__time");

  progressBar.appendChild(progressSecondsPlayed);
  progressBar.appendChild(progressDuration);

  playerState$.subscribe(({ isPlaying, secondsPlayed, duration }) => {
    button.innerText = isPlaying ? "Stop" : "Start";

    progressSecondsPlayed.innerText = humanReadableTime(
      Math.floor(secondsPlayed)
    );
    progressDuration.innerText = humanReadableTime(
      Math.floor(duration) - Math.floor(secondsPlayed)
    );
  });

  const container = document.createElement("div");
  container.classList.add("container");

  container.appendChild(progressBar);
  container.appendChild(button);
  document.body.appendChild(container);
};
